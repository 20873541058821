import React, { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import Router from 'next/router';

import Cookies from 'js-cookie';

import CookieConsent from './cookie-consent/CookieConsent';
import CookieSettingsModal from './cookie-consent/CookieSettingsModal';
import { AudurCookies } from '../types/Types';
import { trackPage } from '../utils/Analytics';

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', (url) => {
  trackPage(url);
});

type PageProps = {
  style?: CSSProperties;
  children: JSX.Element | JSX.Element[];
};

const Page: FunctionComponent<PageProps> = ({ style, children }) => {
  const { CookiePolicyClosed } = AudurCookies;
  const [cookieSettingsModal, setShowCookieSettingsModal] = useState(false);
  const [cookieConsentBannerVisible, setCookieConsentBannerVisible] = useState(true);

  const setPolicyClosedCookie = () => {
    Cookies.set(CookiePolicyClosed, 'true', { expires: 30 });
    setCookieConsentBannerVisible(false);
  };

  const getIsCookiePolicyClosed = () => {
    return Cookies.get(CookiePolicyClosed) && Cookies.get(CookiePolicyClosed) === 'true';
  };

  useEffect(() => {
    setCookieConsentBannerVisible(!getIsCookiePolicyClosed());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={style} suppressHydrationWarning>
      <CookieSettingsModal visible={cookieSettingsModal} onClose={() => setShowCookieSettingsModal(false)} />
      {children}
      {typeof window && (
        <CookieConsent
          visible={cookieConsentBannerVisible}
          onAcceptCookies={setPolicyClosedCookie}
          onClickCookieSettings={() => setShowCookieSettingsModal(!cookieSettingsModal)}
        />
      )}
    </div>
  );
};

export default Page;
