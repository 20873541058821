import React, { FunctionComponent } from 'react';
import { Colors } from '@kvika/audur-theme';

type CloseSvgProps = {
  fillColor?: Colors;
};

type AudurIconProps = {
  fillColor?: Colors;
  width?: number;
  height?: number;
  viewBox?: string;
};

export const CloseSvg: FunctionComponent<CloseSvgProps> = ({ fillColor }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
        fill={fillColor ? fillColor : Colors.Beige}
      />
    </svg>
  );
};

export const AudurIcon: FunctionComponent<AudurIconProps> = ({
  fillColor,
  width = 136,
  height = 75,
  viewBox = '10 0 136 75',
}) => {
  return (
    <svg width={`${width}`} height={`${height}`} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M119.356 27.0938C119.281 27.1361 119.218 27.189 119.143 27.2313V25.9772H113.657V47.7263H119.143V36.349C119.185 34.866 119.77 33.4491 120.787 32.3643C121.331 31.7988 121.988 31.3534 122.716 31.0571C123.444 30.7608 124.226 30.6202 125.012 30.6445V25.5803C123.026 25.5864 121.077 26.1081 119.356 27.0938Z"
        fill="#F8ECDC"
      />
      <path
        d="M52.3589 36.6349C52.3589 38.6563 52.2471 39.9775 52.0237 40.5984C51.771 41.3572 51.2805 42.0155 50.6242 42.477C49.9421 42.9196 49.1396 43.1431 48.3256 43.1173C47.5189 43.1437 46.7243 42.9179 46.0535 42.4717C45.4171 42.0225 44.9417 41.3828 44.6966 40.646C44.4128 39.8628 44.2727 38.4658 44.2762 36.4549V26.1254H38.8435V35.0579C38.8435 38.4976 39.0474 40.863 39.4554 42.1542C39.9731 43.9274 41.0679 45.4796 42.5682 46.5675C44.0262 47.6258 45.9382 48.155 48.3043 48.155C50.1949 48.2312 52.0672 47.7607 53.6945 46.8003C55.1305 45.8626 56.2444 44.5112 56.8871 42.9268C57.4777 41.5509 57.7757 38.9844 57.7757 35.2431V26.1254H52.3589V36.6349Z"
        fill="#F8ECDC"
      />
      <path
        d="M103.281 36.6348C103.281 38.6563 103.168 39.9775 102.941 40.5984C102.688 41.3572 102.197 42.0155 101.541 42.4769C100.861 42.9195 100.06 43.143 99.2477 43.1172C98.4395 43.1437 97.6431 42.918 96.9703 42.4716C96.3399 42.02 95.8703 41.3806 95.6294 40.646C95.3527 39.8628 95.2144 38.4658 95.2144 36.4549V26.1254H89.7444V35.0579C89.7444 38.487 89.9555 40.8524 90.3776 42.1541C90.8884 43.9223 91.9713 45.4735 93.4585 46.5675C94.9164 47.6258 96.8284 48.155 99.1945 48.155C101.087 48.231 102.961 47.7605 104.59 46.8003C106.025 45.8616 107.139 44.5105 107.783 42.9267C108.379 41.5509 108.677 38.9844 108.677 35.2431V26.1254H103.281V36.6348Z"
        fill="#F8ECDC"
      />
      <path
        d="M27.8822 27.2314C27.8077 27.189 27.7492 27.1361 27.6747 27.0938C25.9285 26.0942 23.9481 25.5704 21.9333 25.575C18.9474 25.6072 16.0958 26.8137 14.0019 28.9308C11.908 31.0479 10.7421 33.9034 10.7591 36.8729C10.7312 38.3532 11.0075 39.8236 11.5711 41.194C12.1347 42.5643 12.9738 43.8058 14.0369 44.8423C15.0567 45.9062 16.2862 46.7494 17.6485 47.3194C19.0108 47.8894 20.4767 48.1738 21.9546 48.1549C23.9788 48.1676 25.9698 47.6433 27.7226 46.6362L27.8822 46.5357V47.7263H33.3735V25.9772H27.8822V27.2314ZM26.2327 41.3656C25.6915 41.9376 25.0349 42.3893 24.3058 42.6911C23.5768 42.993 22.7919 43.1382 22.0025 43.1172C21.2196 43.1394 20.4408 42.9967 19.7172 42.6985C18.9937 42.4003 18.3418 41.9533 17.8042 41.3868C16.6741 40.1505 16.0796 38.5211 16.1493 36.8518C16.1187 36.0342 16.251 35.2186 16.5387 34.4521C16.8263 33.6856 17.2637 32.9832 17.8255 32.3855C18.3623 31.8174 19.0139 31.3686 19.7374 31.0686C20.4609 30.7685 21.2401 30.624 22.0237 30.6445C22.8152 30.6161 23.6037 30.7546 24.3374 31.051C25.0711 31.3475 25.7333 31.7951 26.2806 32.3644C27.2813 33.4559 27.8487 34.8722 27.8769 36.349V37.3439C27.8422 38.8394 27.2572 40.2703 26.2327 41.3656Z"
        fill="#F8ECDC"
      />
      <path
        d="M79.2833 27.2313C79.2088 27.189 79.1449 27.1361 79.0758 27.0937C77.3296 26.0942 75.3492 25.5704 73.3344 25.575C70.3494 25.6099 67.4994 26.8173 65.4061 28.9338C63.3128 31.0503 62.146 33.9042 62.1602 36.8729C62.134 38.3539 62.4121 39.8247 62.9775 41.195C63.543 42.5654 64.3839 43.8065 65.4486 44.8423C66.4678 45.9055 67.6965 46.7484 69.0578 47.3183C70.4192 47.8883 71.884 48.1731 73.361 48.1549C75.3828 48.1756 77.3736 47.6605 79.129 46.6626C80.8598 45.6875 82.2895 44.2601 83.2634 42.5351C84.2073 40.8941 84.7215 39.0435 84.7586 37.1534V18.9603H79.2833V27.2313ZM77.6338 41.3656C77.0811 41.9173 76.4243 42.355 75.7011 42.6537C74.9779 42.9524 74.2026 43.1061 73.4195 43.1061C72.6364 43.1061 71.8611 42.9524 71.1379 42.6537C70.4147 42.355 69.758 41.9173 69.2053 41.3656C68.0861 40.1328 67.5011 38.5109 67.577 36.8517C67.5464 36.0341 67.6787 35.2186 67.9664 34.4521C68.254 33.6855 68.6913 32.9832 69.2531 32.3855C69.7876 31.8201 70.4357 31.3729 71.1553 31.0729C71.875 30.773 72.6501 30.627 73.4302 30.6445C74.2215 30.6167 75.0098 30.7556 75.7434 31.0519C76.477 31.3483 77.1393 31.7956 77.687 32.3643C78.6877 33.4559 79.2551 34.8721 79.2833 36.349V37.1269C79.2951 38.696 78.7074 40.211 77.6391 41.3656H77.6338Z"
        fill="#F8ECDC"
      />
      <path d="M79.2886 13.4993H73.7973V18.9604H79.2886V13.4993Z" fill={fillColor ? fillColor : Colors.Beige} />
      <path
        d="M73.7973 61.3843V55.4364H75.9257C76.7174 55.4364 77.4767 55.7492 78.0365 56.3059C78.5963 56.8626 78.9108 57.6177 78.9108 58.4051C78.9108 59.1924 78.5963 59.9475 78.0365 60.5042C77.4767 61.061 76.7174 61.3738 75.9257 61.3738L73.7973 61.3843ZM75.8831 60.326C76.3716 60.3003 76.8316 60.0893 77.1683 59.7363C77.5049 59.3833 77.6926 58.9154 77.6926 58.4289C77.6926 57.9424 77.5049 57.4744 77.1683 57.1215C76.8316 56.7685 76.3716 56.5575 75.8831 56.5318H74.9466V60.326H75.8831Z"
        fill="#F8ECDC"
      />
      <path
        d="M83.87 59.3152C83.8658 59.7424 83.7346 60.1587 83.4929 60.5119C83.2512 60.865 82.9099 61.1391 82.5119 61.2997C82.1139 61.4602 81.677 61.5001 81.2562 61.4142C80.8355 61.3283 80.4497 61.1205 80.1475 60.817C79.8452 60.5135 79.6401 60.1278 79.5578 59.7086C79.4755 59.2893 79.5199 58.8553 79.6852 58.461C79.8505 58.0668 80.1294 57.73 80.4869 57.4931C80.8443 57.2562 81.2642 57.1298 81.6937 57.1298C81.9831 57.1231 82.2709 57.1753 82.5393 57.283C82.8078 57.3908 83.0513 57.5519 83.2551 57.7565C83.4588 57.961 83.6184 58.2048 83.7241 58.4728C83.8298 58.7408 83.8795 59.0275 83.87 59.3152ZM82.7473 59.3152C82.7247 59.1124 82.6436 58.9204 82.5138 58.7623C82.384 58.6042 82.2111 58.4868 82.0157 58.4243C81.8204 58.3617 81.611 58.3567 81.4129 58.4097C81.2147 58.4628 81.0362 58.5717 80.8989 58.7233C80.7615 58.8749 80.6712 59.0628 80.6387 59.2643C80.6063 59.4658 80.6331 59.6724 80.716 59.8591C80.7988 60.0458 80.9342 60.2047 81.1058 60.3166C81.2774 60.4286 81.4778 60.4888 81.6831 60.49C81.8332 60.4909 81.9819 60.4602 82.1193 60.3999C82.2567 60.3396 82.3797 60.251 82.4802 60.1401C82.5807 60.0291 82.6565 59.8982 82.7025 59.7561C82.7485 59.6139 82.7638 59.4637 82.7473 59.3152ZM82.0236 56.7593H81.1244L81.8906 55.5052H83.1676L82.0236 56.7593Z"
        fill="#F8ECDC"
      />
      <path
        d="M86.1155 57.2568H86.9509V58.2463H86.1155V59.9662C86.1155 60.3313 86.2858 60.4477 86.6051 60.4477C86.7215 60.4502 86.8377 60.4377 86.9509 60.4107V61.3314C86.7485 61.4057 86.5334 61.4398 86.3177 61.432C86.144 61.4494 85.9686 61.4288 85.8037 61.3717C85.6388 61.3146 85.4885 61.2223 85.3633 61.1013C85.238 60.9803 85.1409 60.8335 85.0788 60.6712C85.0166 60.509 84.9909 60.3351 85.0034 60.1619V58.2463H84.2585V57.2568H84.466C84.5534 57.2635 84.6413 57.251 84.7233 57.2201C84.8053 57.1892 84.8795 57.1407 84.9405 57.078C85.0014 57.0154 85.0478 56.9401 85.0761 56.8576C85.1045 56.775 85.1142 56.6873 85.1045 56.6006V56.0238H86.1155V57.2568Z"
        fill="#F8ECDC"
      />
      <path
        d="M89.2602 57.2568H90.0903V58.2463H89.2602V59.9662C89.2602 60.3313 89.4252 60.4477 89.7445 60.4477C89.8609 60.4505 89.9772 60.4381 90.0903 60.4107V61.3314C89.8896 61.4053 89.6763 61.4394 89.4624 61.432C89.2887 61.4494 89.1133 61.4288 88.9484 61.3717C88.7835 61.3146 88.6332 61.2223 88.508 61.1013C88.3828 60.9803 88.2857 60.8335 88.2235 60.6712C88.1614 60.509 88.1356 60.3351 88.1482 60.1619V58.2463H87.3979V57.2568H87.6054C87.6931 57.2634 87.7811 57.2508 87.8633 57.22C87.9455 57.1891 88.02 57.1407 88.0813 57.0782C88.1427 57.0156 88.1896 56.9404 88.2186 56.8579C88.2476 56.7754 88.2581 56.6876 88.2493 56.6006V56.0238H89.2602V57.2568Z"
        fill="#F8ECDC"
      />
      <path
        d="M91.6015 55.2141C91.7381 55.2141 91.8716 55.2544 91.9852 55.3297C92.0989 55.4051 92.1875 55.5122 92.24 55.6376C92.2925 55.763 92.3065 55.901 92.2803 56.0343C92.254 56.1676 92.1887 56.2902 92.0925 56.3866C91.9962 56.4831 91.8735 56.549 91.7397 56.5761C91.6058 56.6033 91.4669 56.5904 91.3404 56.5391C91.2139 56.4879 91.1055 56.4005 91.0289 56.2881C90.9523 56.1757 90.9108 56.0432 90.9098 55.9074C90.9083 55.8164 90.9252 55.726 90.9594 55.6416C90.9936 55.5572 91.0444 55.4805 91.1089 55.4159C91.1733 55.3513 91.2501 55.3002 91.3347 55.2655C91.4193 55.2309 91.51 55.2134 91.6015 55.2141ZM91.0428 61.3843V57.2568H92.1602V61.3843H91.0428Z"
        fill="#F8ECDC"
      />
      <path
        d="M95.8848 58.3627C95.7747 58.3387 95.6623 58.3262 95.5496 58.3257C94.9749 58.3257 94.4854 58.6062 94.4854 59.4899V61.3843H93.3627V57.2568H94.4269V57.8653C94.5396 57.6554 94.7113 57.4827 94.9211 57.368C95.1308 57.2534 95.3695 57.2019 95.6081 57.2197C95.6949 57.2143 95.782 57.2143 95.8688 57.2197L95.8848 58.3627Z"
        fill="#F8ECDC"
      />
      <path
        d="M100.716 58.9131L99.9767 59.728V61.3843H98.8168V55.4364H99.9767V58.1246L102.371 55.4364H103.893L101.525 58.0241L103.909 61.3738H102.451L100.716 58.9131Z"
        fill="#F8ECDC"
      />
      <path
        d="M106.378 61.3843H105.255L103.563 57.2673H104.792L105.825 60.0296L106.809 57.2673H107.985L106.378 61.3843Z"
        fill="#F8ECDC"
      />
      <path
        d="M109.225 55.2141C109.361 55.2141 109.495 55.2544 109.608 55.3297C109.722 55.4051 109.811 55.5122 109.863 55.6376C109.916 55.763 109.93 55.901 109.903 56.0343C109.877 56.1676 109.812 56.2902 109.716 56.3866C109.619 56.4831 109.497 56.549 109.363 56.5761C109.229 56.6033 109.09 56.5904 108.964 56.5391C108.837 56.4879 108.729 56.4005 108.652 56.2881C108.575 56.1757 108.534 56.0432 108.533 55.9074C108.531 55.8164 108.548 55.726 108.583 55.6416C108.617 55.5572 108.668 55.4805 108.732 55.4159C108.796 55.3513 108.873 55.3002 108.958 55.2655C109.042 55.2309 109.133 55.2134 109.225 55.2141ZM108.666 61.3843V57.2568H109.789V61.3843H108.666Z"
        fill="#F8ECDC"
      />
      <path
        d="M113.338 58.9872L115.03 61.3738H113.657L112.561 59.7862L112.098 60.2731V61.3314H110.981V55.3147H112.098V58.7861L113.529 57.2568H114.993L113.338 58.9872Z"
        fill="#F8ECDC"
      />
      <path
        d="M118.329 60.9293C118.198 61.1066 118.024 61.2481 117.823 61.3406C117.622 61.4331 117.401 61.4736 117.18 61.4584C116.966 61.4657 116.753 61.4281 116.555 61.3482C116.357 61.2683 116.178 61.1478 116.03 60.9945C115.882 60.8412 115.768 60.6587 115.696 60.4587C115.623 60.2587 115.594 60.0458 115.61 59.8339V57.2568H116.727V59.6328C116.713 59.7384 116.723 59.8458 116.755 59.9475C116.787 60.0492 116.84 60.1427 116.912 60.2218C116.984 60.3008 117.072 60.3633 117.171 60.4051C117.269 60.4468 117.376 60.4668 117.483 60.4636C117.591 60.4694 117.698 60.452 117.799 60.4127C117.899 60.3733 117.99 60.3129 118.064 60.2355C118.139 60.1582 118.196 60.0657 118.232 59.9644C118.267 59.8632 118.281 59.7555 118.27 59.6486V57.2568H119.388V60.6276C119.389 60.8769 119.403 61.126 119.43 61.3738H118.366C118.343 61.2267 118.33 61.0781 118.329 60.9293Z"
        fill="#F8ECDC"
      />
    </svg>
  );
};
