import React, { CSSProperties } from 'react';
import styles from './AudurButton.module.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  displayBlock?: boolean;
  style?: CSSProperties;
}

const AudurButton: React.FunctionComponent<ButtonProps> = ({
  type = 'button',
  className = '',
  onClick,
  displayBlock = false,
  disabled,
  style,
  children,
  ...rest
}) => {
  const arrClassName = [styles.button];
  if (disabled) arrClassName.push(styles.disabled);
  if (displayBlock) arrClassName.push(styles.block);
  if (className !== '') arrClassName.push(className);

  return (
    <button
      type={type}
      className={arrClassName.join(' ')}
      onClick={onClick}
      disabled={disabled}
      {...rest}
      style={style}
    >
      {children}
    </button>
  );
};

export default AudurButton;
