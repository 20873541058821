/* eslint-disable prefer-destructuring */
export const navigationHeaderHeightLargeMobile = 112;
export const navigationHeaderHeightLarge = 152;
export const navigationHeaderHeightSmall = 100;

export const getNavigationHeaderY = (isMobile: boolean) =>
  isMobile
    ? navigationHeaderHeightLargeMobile - navigationHeaderHeightSmall
    : navigationHeaderHeightLarge - navigationHeaderHeightSmall;
export const baseUrl = process.env.NEXT_PUBLIC_API_URL || '';

// Prismic
const PRISMIC_REPOSITORY = process.env.PRISMIC_REPOSITORY_NAME;
const PRISMIC_REF_API_URL = `https://${PRISMIC_REPOSITORY}.prismic.io/api/v2`;
const PRISMIC_GRAPHQL_API_URL = `https://${PRISMIC_REPOSITORY}.prismic.io/graphql`;
const PRISMIC_API_TOKEN = process.env.PRISMIC_API_TOKEN;
const PRISMIC_API_LOCALE = process.env.PRISMIC_REPOSITORY_LOCALE;
const PRISMIC_API_DEFAULT_LOCALE = 'is';

export const prismicConstants = {
  repository: PRISMIC_REPOSITORY,
  refApiUrl: PRISMIC_REF_API_URL,
  gqlApiUrl: PRISMIC_GRAPHQL_API_URL,
  apiToken: PRISMIC_API_TOKEN ?? '',
  apiLocale: PRISMIC_API_LOCALE,
  apiDefaultLocale: PRISMIC_API_DEFAULT_LOCALE,
};

export const ANSWERS_CONTAINER_ID = 'answersContainer';
