import { useState } from 'react';
import Link from 'next/link';

import Cookies from 'js-cookie';
import { Colors } from '@kvika/audur-theme';

import styles from './CookieSettingsModal.module.scss';
import AudurModal from '../modal/AudurModal';
import AudurSwitch from '../AudurSwitch';
import { AudurCookies } from '../../types/Types';
import { AudurButton } from '../audur-buttons';
import { TranslatedStrings } from '../../data/TranslatedStrings';
import { Route } from '../../navigation/Routes';

type Props = {
  visible: boolean;
  onClose(): void;
};

const CookieSettingsModal = ({ visible, onClose }: Props) => {
  const { cookieSettingsStrings, cookieConsentStrings } = TranslatedStrings.is;
  const getStatisticsCookieEnabled = () =>
    !!(Cookies.get(StatisticsCookieApproved) && Cookies.get(StatisticsCookieApproved) === 'true');
  const getMarketingCookieEnabled = () =>
    !!(Cookies.get(MarketingCookieApproved) && Cookies.get(MarketingCookieApproved) === 'true');
  const { StatisticsCookieApproved, MarketingCookieApproved } = AudurCookies;
  const [statisticsCookieEnabled, setStatisticsCookieEnabled] = useState(getStatisticsCookieEnabled());
  const [marketingCookieEnabled, setMarketingCookieEnabled] = useState(getMarketingCookieEnabled());

  const onSave = () => {
    Cookies.set(StatisticsCookieApproved, statisticsCookieEnabled.toString(), { expires: 30 });
    Cookies.set(MarketingCookieApproved, marketingCookieEnabled.toString(), { expires: 30 });
    onClose();
  };

  return (
    <AudurModal isOpen={visible} handleClose={onClose}>
      <div className={styles.contentContainer}>
        <h3>{cookieSettingsStrings.defaultCookieSettingsTitle}</h3>
        <p>{cookieSettingsStrings.defaultCookieSettingsInfo}</p>
        <h3>{cookieSettingsStrings.statisticsCookieSettingsTitle}</h3>
        <div className={styles.switch}>
          <div>{`${cookieSettingsStrings.allowStatisticsCookie} `}</div>
          <div>
            <AudurSwitch toggled={statisticsCookieEnabled} setToggled={setStatisticsCookieEnabled} />
          </div>
        </div>
        <p>{cookieSettingsStrings.statisticsCookieSettingsInfo}</p>
        <h3>{cookieSettingsStrings.marketingCookieSettingsTitle}</h3>
        <div className={styles.switch}>
          <div>{`${cookieSettingsStrings.allowMarketingCookie} `}</div>
          <div>
            <AudurSwitch toggled={marketingCookieEnabled} setToggled={setMarketingCookieEnabled} />
          </div>
        </div>
        <p>{cookieSettingsStrings.marketingCookieSettingsInfo}</p>
        <p>
          <Link href={Route.Cookies} onClick={onClose}>
            {cookieConsentStrings.moreOnCookies}
          </Link>
        </p>
        <div className={styles.buttonContainer}>
          <AudurButton onClick={onSave} style={{ backgroundColor: Colors.LightOrange, width: 200, height: 40 }}>
            {cookieSettingsStrings.saveSettings}
          </AudurButton>
        </div>
      </div>
    </AudurModal>
  );
};

export default CookieSettingsModal;
