// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  release: process.env.VERCEL_GITHUB_COMMIT_SHA,
  environment: process.env.NEXT_PUBLIC_AUDUR_ENV,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0,
  integrations: (list) => [
    ...list,
    new CaptureConsoleIntegration({
      levels: ['error', 'debug', 'warn'],
    }),
  ],
});
