export const TranslatedStrings = {
  is: {
    home: {
      title: 'Stofnaðu sparnaðarreikning hjá Auði með góðum innlánsvöxtum',
      mainContent: {
        linkText: 'Sækja appið',
      },
      helloContent: {
        headline: (
          <h1>
            Halló ég er <em>Auður.</em> Þægileg leið í sparnaði.
          </h1>
        ),
        subheadline: 'Ég býð hæstu mögulegu vexti án binditíma. Vertu með! Það tekur bara 3 mínútur.',
        linkText: 'Nánar',
      },
    },
    calculatorStrings: {
      title: 'Reikna áætlaða ávöxtun',
      clear: 'Hreinsa',
      originalBalance: 'Upphafsstaða',
      monthlyDeposit: 'Mánaðarleg innborgun',
      time: 'Tími',
      months: 'mánuðir',
      savingsTabTitle: 'Óbundinn',
      termTabTitle: 'Bundinn',
      futureTabTitle: 'Framtíðar',
      month: 'mánuður',
      interest: 'Vextir',
      interestRoute: 'Vaxtaleið',
      projectedEarnings: 'Áætluð innstæða í lok tímabils',
      projectedInterests: 'Áætlaðar vaxtatekjur á tímabili',
      audurInterestRate: 'Vextir hjá Auði',
      termSelectionText: 'Vaxtaprósenta og binditími',
      marketInterestRate: 'Meðalvextir á markaði',
      projectedYearlyInflation: 'Áætluð verðbólga á ársgrundvelli',
    },
    navigationStrings: {
      savings: 'Sparnaður',
      faq: 'Algengar spurningar',
      about: 'Um Auði',
      app: 'Appið',
      createAccount: 'Stofna reikning',
      login: 'Innskráning',
      contactUs: 'Hafðu samband',
    },
    cookieConsentStrings: {
      cookieConsentTitle:
        'Vefur Auðar notar vefkökur til þessa að safna upplýsingum um umferð á vefnum, til að bæta upplifun og vegna auglýsingabirtinga.',
      moreOnCookies: 'Nánar um vefkökunotkun',
      cookieSettings: 'Stillingar',
      agreeCookiePolicy: 'Samþykkja',
      readMore: 'Lesa meira',
      weUseCookies: 'Við notum vefkökur',
    },
    cookieSettingsStrings: {
      defaultCookieSettingsTitle: 'Nauðsynlegar vefkökur',
      defaultCookieSettingsInfo:
        'Sumar vefkökur eru nauðsynlegar til að vefsvæðið virki eðlilega og eru þær sjálfkrafa virkar',
      statisticsCookieSettingsTitle: 'Tölfræði',
      statisticsCookieSettingsInfo:
        'Tölfræðilegar vefkökur hjálpa okkur að bæta heildarupplifun gesta á vefsvæðinu með því að safna upplýsingum um notkun.',
      allowStatisticsCookie: 'Leyfa tölfræðilegar vefkökur',
      marketingCookieSettingsTitle: 'Markaðssetning',
      marketingCookieSettingsInfo:
        'Vefkökur fyrir markaðssetningu eru notaðar til að safna upplýsingum um hegðun notanda til að geta birt viðeigandi auglýsingar.',
      allowMarketingCookie: 'Leyfa vefkökur fyrir markaðssetningu',
      saveSettings: 'Vista stillingar',
      cookieSettings: 'Stillingar vefkaka',
    },
    footerStrings: {
      trademarkString: 'Auður er vörumerki Kviku banka hf.',
      furtherInfo: 'Nánari upplýsingar eru á kvika.is',
      startSaving: 'Byrjaðu að spara',
    },
    menuStrings: {
      legalNotice: 'Lagalegir fyrirvarar',
      cookieTerms: 'Vefkökuskilmálar',
      businessTerms: 'Viðskiptaskilmálar Auðar',
      termAccountTerms: 'Skilmálar bundins reiknings',
      appTerms: 'App skilmálar',
      futureAccountTerms: 'Skilmálar græns framtíðarreiknings',
      privacyPolicy: 'Persónuverndarstefna',
      termsAndPrivacyPolicy: 'Skilmálar og persónuverndarstefna',
      metaTags: {
        title: 'Skilmálar',
        description: 'Auður er fjármálaþjónusta á netinu',
        imageUrl:
          'https://images.prismic.io/audur/ed8073b4-49f0-447d-b4bf-03e0b5fa75b5_audur_meta_tag.png?auto=compress,format',
      },
    },
    contact: {
      title: 'Hafa samband',
      contact: 'Hafa samband',
      subtitle: 'Hér getur þú sent okkur fyrirspurn eða hringt í síma 585 6500.',
      form: {
        name: 'Nafn',
        email: 'Netfang',
        message: 'Fyrirspurn',
      },
      send: 'Senda',
      nameError: 'Vinsamlegast fyllið inn nafn',
      emailError: 'Netfang er ekki gilt',
      messageError: 'Vinsamlegast fyllið inn fyrirspurn',
      metaTags: {
        title: 'Hafa samband',
        description: 'Auður - auðvelt - auðvitað!',
        imageUrl:
          'https://images.prismic.io/audur/ed8073b4-49f0-447d-b4bf-03e0b5fa75b5_audur_meta_tag.png?auto=compress,format',
      },
      toastSuccessGeneral: 'Fyrirsprun hefur verið send!',
      toastSuccessCompliance: 'Kvörtun hefur verið send!',
      toastErrorGeneral: 'Ekki tókst að senda fyrirspurn',
      toastErrorCompliance: 'Ekki tókst að senda kvörtun',
      homepage: 'Aftur á forsíðu',
      close: 'Loka',
      modalTitle: `Takk fyrir! Fyrirspurn þín hefur verið móttekin.`,
      modalDescription: 'Við munum hafa samband eins fljótt og hægt er.',
      failedModalTitle: 'Villa!',
      failedModalDescription:
        ' Því miður kom upp villa þegar við reyndum að senda fyrirspurn þína. Vinsamlegast reyndu aftur síðar eða hafðu samband við okkur beint í gegnum audur@audur.is',
    },
    createAccount: {
      title: 'Stofnaðu reikning hjá Auði með góðum innlánsvöxtum',
      heading: 'Stofna reikning',
      startSaving: 'Byrjaðu að spara með Auði.',
      description:
        'Það tekur aðeins nokkrar mínútur að stofna reikning hjá Auði og ferlið er einfalt og þægilegt fyrir alla. Til þess að stofna reikning þarftu að vera með íslenska kennitölu, hafa náð 18 ára aldri og vera með skattalegt heimilisfesti á Íslandi.',
      step1: 'Auðkenndu þig með rafrænum skilríkjum',
      step2: 'Svaraðu nokkrum spurningum',
      step3: 'Leggðu sparnaðinn þinn inn',
      metaTags: {
        title: 'Vertu með!',
        description: 'Það tekur aðeins 3 mínútur að vera með',
        imageUrl:
          'https://images.prismic.io/audur/ed8073b4-49f0-447d-b4bf-03e0b5fa75b5_audur_meta_tag.png?auto=compress,format',
      },
    },
    faq: {
      title: 'Algengar spurningar um innlánsreikninga Auðar',
      heading: 'Algengar spurningar',
      savingsAccount: 'Sparnaðarreikningur',
      termDepositAccount: 'Bundinn reikningur',
      contact: 'Hafa samband',
    },
    Page404: {
      heading: '404',
      subHeading: 'Þessi síða er ekki til',
      linkText: 'Fara aftur heim',
    },
    Page500: {
      heading: 'Villa hefur komið upp',
      subHeading: 'Villa hefur komið upp á þessari síðu, vinsamlega reyndu aftur.',
      linkText: 'Fara aftur heim',
    },
    sopraStrings: {
      button: 'Nánar hér',
      heading:
        'Smá vesen, vegna tæknilegra örðugleika gætu sumar aðgerðir, t.d. innskráning, ekki virkað sem skyldi. Við erum að vinna í málinu.',
    },
  },
};
