import Cookies from 'js-cookie';
import {
  AudurContentPage,
  AudurContentPageBody,
  AudurEditorialContentLinkGroup,
  AudurFooter,
  AudurHeader,
  AudurLegal,
  AudurLegalBody,
  AudurLink,
  FaqCategory,
  FaqCategoryBody,
  Maybe,
} from '@kvika/audur-prismic-types';
import { AudurPrismicClient, getNodesFromConnection } from '@kvika/audur-prismic-client';
import AudurClient from '@kvika/audur-api-client-v2';
import KvikaClient from '@kvika/api-client';

import { DisruptionTypeEnum } from '@kvika/api-types';
import { AudurCookies, MetaTagInfo, ServiceStatusMode } from '../types/Types';
import { navigationHeaderHeightSmall, prismicConstants } from './Constants';

export const stringToInt = (stringToParse: string): number =>
  Number.isInteger(Number.parseInt(stringToParse, 10)) ? Number.parseInt(stringToParse, 10) : 0;

/**
 * Cookies
 */
export const isCookieDeclined = (cookie: AudurCookies) => !!(Cookies.get(cookie) && Cookies.get(cookie) === 'false');
export const isCookieAccepted = (cookie: AudurCookies) => !!(!Cookies.get(cookie) || Cookies.get(cookie) === 'true');

const getPrismicMetaTags = (page: FaqCategory | AudurContentPage | AudurLegal) => {
  if (page.body) {
    switch (page.__typename) {
      case 'FaqCategory':
        return page.body.find((slice) => slice.type === 'meta_tags') as FaqCategoryBody;
      case 'AudurContentPage':
        return page.body.find((slice) => slice.type === 'meta_tags') as AudurContentPageBody;
      case 'AudurLegal':
        return page.body.find((slice) => slice.type === 'meta_tags') as AudurLegalBody;
      default:
        return null;
    }
  }
  return null;
};

export const getMetaTags = (page: Maybe<FaqCategory> | Maybe<AudurContentPage> | Maybe<AudurLegal>): MetaTagInfo => {
  if (page) {
    const item = getPrismicMetaTags(page);
    if (item) {
      const { primary } = item;
      if (primary) {
        return {
          title: primary.metaTitle || '',
          description: primary.metaDescription || '',
          imageUrl: primary.metaImage.url || '',
        };
      }
    }
  }

  return {
    title: '',
    description: '',
    imageUrl: '',
  };
};

export const isValidEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getHomeLottieImageDimensions = (isMobile: boolean, isTablet: boolean, width: number, height: number) => {
  if (isMobile) {
    return { width: 375, height: 276 };
  }
  if (isTablet) {
    return { width: 350, height: 500 };
  }
  return { width, height };
};

export const getHomeLottieImageOffset = (isMobile: boolean, isTablet: boolean) => {
  if (isMobile) {
    return { x: -20, y: -120 };
  }
  if (isTablet) {
    return { x: -40, y: -140 };
  }
  return { x: -20, y: -100 };
};

/**
 * Scrolls down to the specified HTML element.
 * @param id ID of the element to scroll to.
 */
export const executeScrollToElement = (id: string) => {
  const element = document.getElementById(id);
  const elementPos = element?.getBoundingClientRect().top ?? 0;
  window.scrollBy({
    top: elementPos - navigationHeaderHeightSmall,
    behavior: 'smooth',
  });
};

export const getPrismicTitle = (linkGroup?: Maybe<AudurEditorialContentLinkGroup[]>) => {
  const link = linkGroup && (linkGroup[0].editorialContentLink as AudurLink);
  return link?.linkTitle ?? '';
};

export const getPrismicHref = (linkGroup?: Maybe<AudurEditorialContentLinkGroup[]>) => {
  const link = linkGroup && (linkGroup[0].editorialContentLink as AudurLink);
  return link?.linkUrl ?? '';
};

export const getAudurFooter = async () => {
  const audurClient = new AudurPrismicClient(prismicConstants.apiToken);

  const audurFooters = await audurClient.getFooter({
    variables: { lang: prismicConstants.apiDefaultLocale },
  });
  const extractedNodes: Array<AudurFooter> = getNodesFromConnection(audurFooters);
  return extractedNodes[0];
};

export const getAudurHeader = async () => {
  const audurClient = new AudurPrismicClient(prismicConstants.apiToken);

  const audurHeaders = await audurClient.getHeader({
    variables: { lang: prismicConstants.apiDefaultLocale },
  });
  const extractedNodes: Array<AudurHeader> = getNodesFromConnection(audurHeaders);
  return extractedNodes[0];
};

export const getAudurApiClient = (): AudurClient => {
  return new AudurClient({
    apiUrl: process.env.NEXT_PUBLIC_AUDUR_API_URL ?? '',
    storeAuthToken: () => undefined,
  });
};

export const getKvikaApiClient = (setServiceStatusMode?: (serviceStatus: ServiceStatusMode) => void): KvikaClient => {
  return new KvikaClient({
    apiUrl: process.env.NEXT_PUBLIC_KVIKA_API_URL ?? '',
    storeAuthToken: () => undefined,
    appToken: process.env.NEXT_PUBLIC_KVIKA_APP_TOKEN ?? '',
    onResponseHeaders: (headers) => {
      if (setServiceStatusMode) {
        setServiceStatusMode({
          serviceStatus: headers['x-disruption-type'] as DisruptionTypeEnum,
          message: headers['x-disruption-message'],
        });
      }
    },
  });
};
