import { motion } from 'framer-motion';
import { Colors } from '@kvika/audur-theme';
import { IconButton } from '@material-ui/core';

import styles from './CookieConsent.module.scss';
import { AudurButton } from '../audur-buttons';
import { TranslatedStrings } from '../../data/TranslatedStrings';
import { CloseSvg } from '../../../public/icons/Icons';

type Props = {
  visible: boolean;
  onAcceptCookies(): void;
  onClickCookieSettings(): void;
};

const CookieConsent = ({ visible, onAcceptCookies, onClickCookieSettings }: Props) => {
  const { cookieConsentStrings } = TranslatedStrings.is;
  return (
    <motion.div
      className={styles.container}
      initial="hidden"
      animate={visible ? 'visible' : 'hidden'}
      variants={{
        hidden: {
          y: 100,
          display: 'none',
          opacity: 0,
          transition: {
            delay: 0.3,
            type: 'tween',
          },
        },
        visible: {
          y: 0,
          display: 'inherit',
          opacity: 1,
          transition: {
            delay: 0.3,
            type: 'tween',
          },
        },
      }}
    >
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <h5 className="dark">{cookieConsentStrings.weUseCookies}</h5>
          <IconButton onClick={onAcceptCookies}>
            <CloseSvg fillColor={Colors.DarkGray} />
          </IconButton>
        </div>

        <p>
          {cookieConsentStrings.cookieConsentTitle}
          <br />
          <span className={styles.inlineMobileLink}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <a onClick={onClickCookieSettings}>{cookieConsentStrings.moreOnCookies}</a>
          </span>
        </p>

        <div className={styles.buttonContainer}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <a onClick={onClickCookieSettings}>{cookieConsentStrings.readMore}</a>
          <AudurButton onClick={onAcceptCookies} style={{ backgroundColor: Colors.LightOrange }}>
            {cookieConsentStrings.agreeCookiePolicy}
          </AudurButton>
        </div>
      </div>
    </motion.div>
  );
};

export default CookieConsent;
