import React, { FunctionComponent } from 'react';
import { Colors } from '@kvika/audur-theme';

import { makeStyles, Switch } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: 80,
    height: 48,
    padding: 8,
    boxShadow: '0px 4px 4px rgba(50, 50, 71, 0.08), 0px 4px 8px rgba(50, 50, 71, 0.06)',
  },
  switchBase: {
    padding: 11,
    color: '#ff6a00',
  },
  thumb: {
    width: 26,
    height: 26,
    backgroundColor: Colors.DarkGray,
  },
  track: {
    backgroundColor: Colors.White,
    opacity: '1 !important',
    borderRadius: 20,
    position: 'relative',
    '&:before, &:after': {
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      width: '50%',
      transform: 'translateY(-50%)',
      color: '#fff',
      textAlign: 'center',
    },
    '&:before': {
      content: '"Já"',
      left: 4,
      opacity: 0,
      color: Colors.DarkGray,
    },
    '&:after': {
      content: '"Nei"',
      right: 4,
      color: Colors.DarkGray,
    },
  },
  checked: {
    '&$switchBase': {
      color: '#185a9d',
      transform: 'translateX(32px)',
      '&:hover': {
        backgroundColor: 'rgba(24,90,257,0.08)',
      },
    },
    '& $thumb': {
      backgroundColor: Colors.Orange,
    },
    '& + $track': {
      backgroundColor: `${Colors.White} !important`,
      '&:before': {
        opacity: 1,
      },
      '&:after': {
        opacity: 0,
      },
    },
  },
});

type Props = {
  toggled: boolean;
  setToggled(toggled: boolean): void;
};

const AudurSwitch: FunctionComponent<Props> = ({ toggled, setToggled }) => {
  const classes = useStyles();

  return <Switch classes={classes} checked={toggled} onChange={(e) => setToggled(e.target.checked)} />;
};

export default AudurSwitch;
