import { CSSProperties } from 'react';
import Link from 'next/link';
import { Colors } from '@kvika/audur-theme';

import styles from './AudurButtonLink.module.scss';

type Props = {
  href: string;
  children: JSX.Element | string;
  fillColor?: Colors;
  borderColor?: Colors;
  style?: CSSProperties;
  openInNewTab?: boolean;
  onClick?(): void;
  className?: string;
};

const AudurButtonLink = ({
  href,
  children,
  fillColor = Colors.Transparent,
  borderColor = Colors.Transparent,
  style,
  openInNewTab,
  onClick,
  className,
}: Props) => {
  return (
    <div className={`${styles.container} ${className}`} style={{ backgroundColor: fillColor, borderColor, ...style }}>
      <Link href={href} target={openInNewTab ? '_blank' : '_self'} onClick={onClick}>
        {children}
      </Link>
    </div>
  );
};

export default AudurButtonLink;
