import React, { FunctionComponent } from 'react';

import { Backdrop, Fade, IconButton, Modal } from '@material-ui/core';

import styles from './AudurModal.module.scss';
import { CloseSvg } from '../../../public/icons/Icons';

type Props = {
  isOpen: boolean;
  style?: React.CSSProperties;
  handleClose(): void;
  children?: JSX.Element | JSX.Element[];
};

const AudurModal: FunctionComponent<Props> = ({ isOpen, style, handleClose, children }) => {
  return (
    <Modal
      className={styles.modal}
      style={style}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <Fade in={isOpen}>
        <div className={styles.modalContent}>
          <IconButton onClick={handleClose} style={{ position: 'absolute', right: 8, top: 10 }}>
            <CloseSvg />
          </IconButton>
          <div>{children}</div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AudurModal;
