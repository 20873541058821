/* eslint-disable @typescript-eslint/no-explicit-any */
import { AudurCookies } from '../types/Types';
import { isCookieAccepted } from './Utils';

export enum SegmentTrackingId {
  CalculatorOnTabSwitch = 'CalculatorOnTabSwitch',
  ContactAudurEmailSent = 'ContactAudurEmailSent',
  NetbankiButtonClicked = 'NetbankiButtonClicked',
}

export enum SegmentPageId {
  Home = 'Home',
}

export type AnalyticsEvent = {
  event: SegmentTrackingId | SegmentPageId;
  properties?: any;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const trackPage = (url: string, properties?: any): void => {
  if (isCookieAccepted(AudurCookies.StatisticsCookieApproved)) {
    window.analytics.page(url, properties);
  }
};

export const trackEvent = (analyticsEvent: AnalyticsEvent): void => {
  if (isCookieAccepted(AudurCookies.StatisticsCookieApproved)) {
    global.analytics.track(analyticsEvent.event, analyticsEvent.properties);
  }
};
