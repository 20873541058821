export enum Route {
  About = '/um-audi',
  AudurApp = '/app',
  AudurWebBank = 'https://netbanki.audur.is',
  BusinessTerms = '/skilmalar/vidskiptaskilmalar',
  Contact = '/hafa-samband',
  Cookies = '/skilmalar/vefkokur',
  CreateAccount = '/stofna-reikning',
  Faq = '/algengar-spurningar',
  Home = '/',
  InterestTable = '/vaxtatafla',
  KvikaWebsite = 'http://www.kvika.is',
  LegalNotice = '/skilmalar/lagalegir-fyrirvarar',
  Savings = '/sparnadur',
  SavingsAccounts = '/sparnadarreikningur',
  TermAccounts = '/bundnir-reikningar',
  TermAccountTerms = '/skilmalar/skilmalar-bundins-reiknings',
  AppTerms = '/skilmalar/app-skilmalar',
  FutureAccounts = '/framtidarreikningur',
  FutureAccountTerms = '/skilmalar/skilmalar-framtidarreikningur',
  PrivacyPolicy = 'https://www.kvika.is/asset/4419/personuverndarstefna-kviku.pdf',
  TermsAndPrivacyPolicy = '/skilmalar-personuverndarstefna',
}
